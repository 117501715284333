<template>
  <div class="main-content">
    <header class="titles-header">
      <div class="titles-header__side">
        <h1 class="titles-header__title">{{$t('adminhome.saludo')}} {{$store.state.user.first_name}},</h1>
        <p class="titles-header__subtitle subtitle">{{$t('adminhome.bienvenida')}} <span class="meaning">+MEANING</span></p>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'AdminHome',
  data () {
    return {}
  },
  methods: {}
}
</script>
